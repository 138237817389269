.p-dialog {
  max-width: 95%;
  border-radius: 12px;

  .p-dialog-header-close {
    background-color: #ebebeb;
    color: #101010;
  }

  > .p-dialog-header {
    justify-content: center;
    background-color: #fff;
    border: none;
    font-size: 24px;
    color: #455775;
    font-weight: 700;
  }

  > .p-dialog-header > .p-dialog-title {
    font-size: 25px;
  }

  > .p-dialog-header > .p-dialog-header-icons {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: #fff;
  }

  > .p-dialog-footer {
    border: none;
    text-align: center;
  }

  &.sucesso {
    border: none;
  }

  &.sucesso > .p-dialog-header,
  &.sucesso > .p-dialog-content,
  &.sucesso > .p-dialog-footer {
    background-color: #38aa46;
    border: none;
    color: #fff;
  }

  &.alerta > .p-dialog-header,
  &.alerta > .p-dialog-content,
  &.alerta > .p-dialog-footer {
    background-color: #ffb700;
    border: none;
    color: #fff;
  }
}

.p-confirm-dialog {
  width: 700px;
  max-width: 90%;

  > .p-dialog-content {
    min-height: 200px;
  }

  > .p-dialog-footer > .p-button {
    padding: 14px 20px;
    border-radius: 0;
  }

  > .p-dialog-footer > .p-confirm-dialog-accept {
    background-color: #3853ac;
    color: #fff;
    font-weight: 600;
  }

  > .p-dialog-footer > .p-confirm-dialog-reject {
    color: #3853ac;
    border: 1px solid #3853ac;
    background-color: #fff;
    font-weight: 600;
  }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #a6a6a6;
}

p-dropdown.ng-touched.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #a80000;
}

.p-dropdown {
  width: 100%;
  border-color: #d3d3d3;
}

.p-calendar {
  width: 100%;
  margin: 5px 0px;
}

.p-datepicker.small {
  th,
  td {
    padding: 0;
  }
}

@media (max-width: 500px) {
  .p-toast {
    max-width: 20rem;
  }
}

.p-inputtext {
  width: 100%;
  padding: 12px;
  border-color: #d3d3d3;
}

.p-breadcrumb {
  background: transparent;
  padding: 0;
  border: none;

  .p-menuitem-text {
    color: #00000066;
  }

  .p-breadcrumb-list li.p-menuitem-separator {
    margin: 0 16px;

    .p-icon {
      width: 12px;
    }
  }

  .p-breadcrumb-list li:last-child .p-menuitem-text {
    color: #000000;
  }
}

.p-paginator {
  background: none;
  border: none;

  .p-paginator-element {
    border: 1px solid #dcdcdc;
    color: #101010;
    border-radius: 8px;
    font-weight: 600;
    margin-left: 2px;
    margin-right: 2px;
  }

  .p-highlight {
    background: #4795d1;
    color: #fff;
  }
}
