/* You can add global styles to this file, and also import other style files */
// @import "../src/assets/sass/variables";
// @import "../src/assets/sass/mixins";
// @import "../src/assets/sass/Regular";
// @import "../src/assets/roboto";
@import "../src/assets/sass/sme_theme.scss";

body {
  margin: 0;
  height: 100%;
  font-family: Roboto, Arial;
  font-size: 14pt;
  touch-action: pan-x pan-y;
}

html {
  height: 100%;
  background-color: #fff;
  color: #101010;
}

@media screen and (min-width: 320px) and (max-width: 768px) and (orientation: portrait) {
  html.landscape {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.input {
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px #8895ab;
  border-radius: 10px !important;
}

.imgBotao {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

button:not(:disabled) {
  cursor: pointer;
}

button:disabled,
a:disabled {
  opacity: 0.5;
}

.botao {
  height: 60px;
  width: 180px;
  background-color: #455775;
  border: 1px solid #455775;
  position: relative;
  font-family: Roboto Medium, Arial;
  margin-top: 60px;
  font-size: 15pt;
  color: #fff;
  border-radius: 0;
  border-radius: 100px;
}

.botao-branco {
  height: 60px;
  width: 180px;
  background-color: #ffffff !important;
  border: 1px solid #455775 !important;
  position: relative;
  font-family: Roboto Medium, Arial !important;
  margin-top: 60px !important;
  color: #455775 !important;
  font-size: 15pt !important;
  border-radius: 100px;
}

.botao:hover {
  opacity: 0.8;
}

.loader {
  border: 5px solid #888888;
  border-top: 5px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  /* -webkit-animation: spin 2s linear infinite; */
  animation: spin 2s linear infinite;

  position: absolute;
  left: 15px;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.conteudoCabecalho {
  background-color: #ffffff;
  position: relative;
  padding: 5px;
}

.conteudoCabecalhoDetalhe {
  font-size: 14pt;
  max-width: 960px;
}

.conteudoDetalhe {
  font-size: 18px;
  line-height: 1.5;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  margin: auto;
  max-width: 960px;
}

.tituloSaudacao {
  font-size: 22px;
  color: #455775;
  padding: 10px 20px 30px 0;
  font-family: Roboto Light, Arial;
  max-width: 960px;
  margin: 0 auto;
}

.ui-chkbox .ui-chkbox-box {
  border-color: #455775 !important;
  border: 2px solid !important;
}

.arrowClippath {
  background: #ffffff;
  height: 25px;
  clip-path: polygon(0 0, 60px 0, 30px 100%);
  max-width: 960px;
  margin: 0 auto;
  top: 50px;
  position: relative;
}

.colorGreen {
  color: #38ac46;
}
.robotoBold {
  font-family: Roboto Bold, Arial;
}

.blur {
  filter: blur(4px);
}

.hide {
  display: none;
}

.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%),
    rgb(255 255 255),
    rgb(255 255 255 / 0%)
  );
}

.mobile {
  display: block;

  @media (min-width: 763px) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (min-width: 763px) {
    display: block;
  }
}
